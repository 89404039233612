module.exports = {
  siteMetadata: {
    title: `ZAC Panda`,
    description: `Artisan, chef d’entreprise, devenez propriétaire ! `,
    author: `La Fabrik`,
  },
  plugins: [
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "H3AWbobHDrNLssc4p8Z2ugtt",
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
      },
    },
    "gatsby-plugin-react-helmet",
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#64a832`,
        theme_color: `#64a832`,
        display: `minimal-ui`,
        icon: `src/images/logo-manifest.png`, // This path is relative to the root of the site.
      },
    },
    `gatsby-plugin-styled-components`,
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // 'gatsby-plugin-offline',
  ],
  pathPrefix: "/panda",
};
